import React, { useState, useEffect } from 'react';

const Map = ({ coordinates, zoom }) => {


  return (
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d926.4644426176413!2d73.00724322609217!3d19.10105897040347!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c0d9bc4889d1%3A0x9a77e72acebdbbf6!2sSHREE%20VIGHNAHARTA%2C%20Sector%207%2C%20Kopar%20Khairane%2C%20Navi%20Mumbai%2C%20Maharashtra%20400709!5e0!3m2!1sen!2sin!4v1713191713788!5m2!1sen!2sin" width="600" height="450" style={{border:"0"}}  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
  );
};

export default Map;
